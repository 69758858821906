import React, { useState, useEffect } from "react"
import axios from "axios"

import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import { Waypoint } from "react-waypoint"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import { Navigation, Pagination } from "swiper"

// Literally don't care about full Width or Contained, I believe all are "full width" as in fill container-new

const WorkFullWidth = ({ data }) => {
  const {
    contents,
    image,
    videoSource,
    type,
    video,
    vimeoVideo,
    thumbnail,
    carouselSlides,
  } = data

  const [ar, setAR] = useState(100 / (1920 / 1080))

  const [shouldPlay, updatePlayState] = useState(false)

  const handleEnterViewport = function () {
    setTimeout(() => {
      updatePlayState(true)
    }, 10)
  }
  const handleExitViewport = function () {
    updatePlayState(false)
  }

  useEffect(() => {
    if (videoSource === "vimeo" && vimeoVideo)
      try {
        axios
          .get(
            `https://vimeo.com/api/oembed.json?url=${`https://vimeo.com/${vimeoVideo.replace(
              "https://vimeo.com/",
              ""
            )}`}`
          )
          .then(response => {
            setAR(100 / (response.data.width / response.data.height))
          })
      } catch (e) {
        console.log(e)
      }
  }, [])

  return (
    <div className="col-12 work-full-width">
      {contents === "video" && (
        <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
          {videoSource === "vimeo" ? (
            <div className="player-wrapper" style={{ paddingTop: `${ar}%` }}>
              <ReactPlayer
                playing={type === "loop" && shouldPlay}
                className="react-player"
                url={`https://vimeo.com/${vimeoVideo.replace(
                  "https://vimeo.com/",
                  ""
                )}`}
                width="100%"
                height="100%"
                controls={type === "player"}
                loop={type === "loop"}
                muted={type === "loop"}
                thumbnail={
                  thumbnail?.localFile.publicURL
                    ? thumbnail.localFile.publicURL
                    : undefined
                }
                config={{
                  playerOptions: {
                    playsinline: true,
                  },
                }}
              />
            </div>
          ) : (
            <div
              className="player-wrapper"
              style={{ paddingTop: `${100 / (video.width / video.height)}%` }}
            >
              <ReactPlayer
                playing={type === "loop" && shouldPlay}
                className="react-player"
                url={video.mediaItemUrl}
                width="100%"
                height="100%"
                controls={type === "player"}
                loop={type === "loop"}
                muted={type === "loop"}
                playsinline
                thumbnail={
                  thumbnail?.localFile.publicURL
                    ? thumbnail.localFile.publicURL
                    : undefined
                }
              />
            </div>
          )}
        </Waypoint>
      )}
      {contents === "carousel" && carouselSlides.length && (
        <Swiper
          navigation={true}
          pagination={true}
          modules={[Navigation, Pagination]}
          className="defaultSwiper"
        >
          {carouselSlides.map((slideImg, index) => (
            <SwiperSlide key={index} index={index}>
              {slideImg && (
                <GatsbyImageWrapper
                  image={slideImg}
                  loading={index === 0 ? "eager" : "lazy"}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {contents === "image" && image && (
        <GatsbyImageWrapper image={image} loading="lazy" />
      )}
    </div>
  )
}

export default WorkFullWidth
