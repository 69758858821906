import React, { useState, useEffect } from "react"
import axios from "axios"
import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import { Waypoint } from "react-waypoint"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import { Navigation, Pagination } from "swiper"

const VerticalHalfContent = ({ data }) => {
  const {
    imageLeft,
    imageRight,
    leftContentType,
    leftPlayerType,
    leftVideoFile,
    leftVideoSource,
    leftVideoThumbnail,
    leftVimeoVideoId,
    leftCarouselSlides,
    rightContentType,
    rightPlayerType,
    rightVideoFile,
    rightVideoSource,
    rightVideoThumbnail,
    rightVimeoVideoId,
    rightCarouselSlides,
  } = data

  const [leftAR, setLeftAR] = useState(100 / (1920 / 1080))
  const [rightAR, setRightAR] = useState(100 / (1920 / 1080))

  const [shouldPlay, updatePlayState] = useState(false)

  const handleEnterViewport = function () {
    setTimeout(() => {
      updatePlayState(true)
    }, 10)
  }
  const handleExitViewport = function () {
    updatePlayState(false)
  }

  useEffect(() => {
    if (leftVideoSource === "vimeo" && leftVimeoVideoId)
      try {
        axios
          .get(
            `https://vimeo.com/api/oembed.json?url=${`https://vimeo.com/${leftVimeoVideoId.replace(
              "https://vimeo.com/",
              ""
            )}`}`
          )
          .then(response => {
            setLeftAR(100 / (response.data.width / response.data.height))
          })
      } catch (e) {
        console.log(e)
      }

    if (rightVideoSource === "vimeo" && rightVimeoVideoId)
      try {
        axios
          .get(
            `https://vimeo.com/api/oembed.json?url=${`https://vimeo.com/${rightVimeoVideoId.replace(
              "https://vimeo.com/",
              ""
            )}`}`
          )
          .then(response => {
            setRightAR(100 / (response.data.width / response.data.height))
          })
      } catch (e) {
        console.log(e)
      }
  }, [])

  return (
    <>
      <div className="col-6 vertical-half-images">
        {leftContentType === "video" && (
          <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
            {leftVideoSource === "vimeo" ? (
              <div
                className="player-wrapper"
                style={{ paddingTop: `${leftAR}%` }}
              >
                <ReactPlayer
                  playing={leftPlayerType === "loop" && shouldPlay}
                  className="react-player"
                  url={`https://vimeo.com/${leftVimeoVideoId.replace(
                    "https://vimeo.com/",
                    ""
                  )}`}
                  width="100%"
                  height="100%"
                  controls={leftPlayerType === "player"}
                  loop={leftPlayerType === "loop"}
                  muted={leftPlayerType === "loop"}
                  thumbnail={
                    leftVideoThumbnail?.localFile.publicURL
                      ? leftVideoThumbnail.localFile.publicURL
                      : undefined
                  }
                  config={{
                    playerOptions: {
                      playsinline: true,
                    },
                  }}
                />
              </div>
            ) : (
              <div
                className="player-wrapper"
                style={{
                  paddingTop: `${
                    100 / (leftVideoFile.width / leftVideoFile.height)
                  }%`,
                }}
              >
                <ReactPlayer
                  playing={leftPlayerType === "loop" && shouldPlay}
                  className="react-player"
                  url={leftVideoFile.mediaItemUrl}
                  width="100%"
                  height="100%"
                  controls={leftPlayerType === "player"}
                  loop={leftPlayerType === "loop"}
                  muted={leftPlayerType === "loop"}
                  playsinline
                  thumbnail={
                    leftVideoThumbnail?.localFile.publicURL
                      ? leftVideoThumbnail.localFile.publicURL
                      : undefined
                  }
                  light={leftVideoThumbnail?.localFile.publicURL ?? false}
                />
              </div>
            )}
          </Waypoint>
        )}
        {leftContentType === "carousel" && leftCarouselSlides.length && (
          <Swiper
            navigation={true}
            pagination={true}
            modules={[Navigation, Pagination]}
            className="defaultSwiper"
          >
            {leftCarouselSlides.map((slideImg, index) => (
              <SwiperSlide key={index} index={index}>
                {slideImg && (
                  <GatsbyImageWrapper
                    image={slideImg}
                    loading={index === 0 ? "eager" : "lazy"}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {leftContentType === "image" && imageLeft && (
          <GatsbyImageWrapper image={imageLeft} />
        )}
      </div>
      <div className="col-6 vertical-half-images">
        {rightContentType === "video" && (
          <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
            {rightVideoSource === "vimeo" ? (
              <div
                className="player-wrapper"
                style={{ paddingTop: `${rightAR}%` }}
              >
                <ReactPlayer
                  playing={rightPlayerType === "loop" && shouldPlay}
                  className="react-player"
                  url={`https://vimeo.com/${rightVimeoVideoId.replace(
                    "https://vimeo.com/",
                    ""
                  )}`}
                  width="100%"
                  height="100%"
                  controls={rightPlayerType === "player"}
                  loop={rightPlayerType === "loop"}
                  muted={rightPlayerType === "loop"}
                  thumbnail={
                    rightVideoThumbnail?.localFile.publicURL
                      ? rightVideoThumbnail.localFile.publicURL
                      : undefined
                  }
                  config={{
                    playerOptions: {
                      playsinline: true,
                    },
                  }}
                />
              </div>
            ) : (
              <div
                className="player-wrapper"
                style={{
                  paddingTop: `${
                    100 / (rightVideoFile.width / rightVideoFile.height)
                  }%`,
                }}
              >
                <ReactPlayer
                  playing={rightPlayerType === "loop" && shouldPlay}
                  className="react-player"
                  url={rightVideoFile.mediaItemUrl}
                  width="100%"
                  height="100%"
                  controls={rightPlayerType === "player"}
                  loop={rightPlayerType === "loop"}
                  muted={rightPlayerType === "loop"}
                  playsinline
                  thumbnail={
                    rightVideoThumbnail?.localFile.publicURL
                      ? rightVideoThumbnail.localFile.publicURL
                      : undefined
                  }
                />
              </div>
            )}
          </Waypoint>
        )}
        {rightContentType === "carousel" && rightCarouselSlides.length && (
          <Swiper
            navigation={true}
            pagination={true}
            modules={[Navigation, Pagination]}
            className="defaultSwiper"
          >
            {rightCarouselSlides.map((slideImg, index) => (
              <SwiperSlide key={index} index={index}>
                {slideImg && (
                  <GatsbyImageWrapper
                    image={slideImg}
                    loading={index === 0 ? "eager" : "lazy"}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {rightContentType === "image" && imageRight && (
          <GatsbyImageWrapper image={imageRight} />
        )}
      </div>
    </>
  )
}

export default VerticalHalfContent
