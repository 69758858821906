import React, { useState, useEffect } from "react"
import axios from "axios"

import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import { Waypoint } from "react-waypoint"
import ReactPlayer from "react-player"

//Literally don't care about full Width or Contained, I believe all are "full width" as in fill container-new

const WorkContained = ({ data }) => {
  const { contents, graphic, videoSource, type, video, vimeoVideo, thumbnail } =
    data

  const [shouldPlay, updatePlayState] = useState(false)

  const handleEnterViewport = function () {
    setTimeout(() => {
      updatePlayState(true)
    }, 10)
  }
  const handleExitViewport = function () {
    updatePlayState(false)
  }

  const [ar, setAR] = useState(100 / (1920 / 1080))

  useEffect(() => {
    if (videoSource === "vimeo" && vimeoVideo)
      try {
        axios
          .get(
            `https://vimeo.com/api/oembed.json?url=${`https://vimeo.com/${vimeoVideo.replace(
              "https://vimeo.com/",
              ""
            )}`}`
          )
          .then(response => {
            setAR(100 / (response.data.width / response.data.height))
          })
      } catch (e) {
        console.log(e)
      }
  }, [])

  return (
    <div className="col-12 work-contained">
      {contents === "video" && (
        <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
          {videoSource === "vimeo" ? (
            <div className="player-wrapper" style={{ paddingTop: `${ar}%` }}>
              <ReactPlayer
                playing={type === "loop" && shouldPlay}
                className="react-player"
                url={`https://vimeo.com/${vimeoVideo.replace(
                  "https://vimeo.com/",
                  ""
                )}`}
                width="100%"
                height="100%"
                controls={type === "player"}
                loop={type === "loop"}
                muted={type === "loop"}
                thumbnail={
                  thumbnail?.localFile.publicURL
                    ? thumbnail.localFile.publicURL
                    : undefined
                }
                config={{
                  playerOptions: {
                    playsinline: true,
                  },
                }}
              />
            </div>
          ) : (
            <div
              className="player-wrapper"
              style={{ paddingTop: `${100 / (video.width / video.height)}%` }}
            >
              <ReactPlayer
                playing={type === "loop" && shouldPlay}
                className="react-player"
                url={video.mediaItemUrl}
                width="100%"
                height="100%"
                controls={type === "player"}
                loop={type === "loop"}
                muted={type === "loop"}
                playsinline
                thumbnail={
                  thumbnail?.localFile.publicURL
                    ? thumbnail.localFile.publicURL
                    : undefined
                }
              />
            </div>
          )}
        </Waypoint>
      )}
      {contents === "image" && graphic && (
        <GatsbyImageWrapper image={graphic} loading="lazy" />
      )}
    </div>
  )
}

export default WorkContained
