import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout"
import Seo from "@components/seo"
import WorkContained from "@components/work/WorkContained"
import WorkFullWidth from "@components/work/WorkFullWidth"
import VerticalHalfContent from "@components/work/VerticalHalfContent"
import WorkLayout from "@components/work/WorkLayout"

import "@styles/work/work.scss"

export const query = graphql`
  query ($id: String) {
    wpWork(id: { eq: $id }) {
      title
      databaseId
      seo {
        metaDesc
        readingTime
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      workFields {
        activities
        contentRows {
          ... on WpWork_Workfields_ContentRows_FullWidthBlock {
            fieldGroupName
            video {
              mediaItemUrl
              width
              height
            }
            contents
            image {
              altText
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            type
            videoSource
            vimeoVideo
            thumbnail {
              localFile {
                publicURL
              }
            }
            carouselSlides {
              altText
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          ... on WpWork_Workfields_ContentRows_ContainedBlock {
            fieldGroupName
            contents
            video {
              mediaItemUrl
              width
              height
            }
            graphic {
              altText
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            type
            videoSource
            vimeoVideo
            thumbnail {
              localFile {
                publicURL
              }
            }
          }
          ... on WpWork_Workfields_ContentRows_VerticalHalfContent {
            fieldGroupName
            imageLeft {
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageRight {
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            leftContentType
            leftPlayerType
            leftVideoFile {
              mediaItemUrl
              width
              height
            }
            leftVideoSource
            leftVideoThumbnail {
              localFile {
                publicURL
              }
            }
            leftVimeoVideoId
            leftCarouselSlides {
              altText
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
            }
            rightContentType
            rightPlayerType
            rightVideoFile {
              mediaItemUrl
              width
              height
            }
            rightVideoSource
            rightVideoThumbnail {
              localFile {
                publicURL
              }
            }
            rightVimeoVideoId
            rightCarouselSlides {
              altText
              localFile {
                ext
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
        }
        darkMode
        location
        newDescription
        vimeoVideoId
        showVideoThumbnails
      }
    }
  }
`

const WpWork = ({
  data: {
    wpWork: { title, databaseId, seo, workFields },
  },
}) => {
  return (
    <Layout
      footerMargin={false}
      headerData={{
        title,
        description: workFields.newDescription,
        activities: workFields.activities,
      }}
      darkMode={workFields.darkMode}
      pageId={databaseId}
    >
      {seo && <Seo title={title} description={seo.metaDesc} seo={seo} />}
      <div className="container-new">
        <div className="row cpt-work-row">
          {workFields.contentRows.map((content, index) => {
            if (
              content.fieldGroupName ===
              "Work_Workfields_ContentRows_ContainedBlock"
            ) {
              return <WorkContained key={index} data={content} />
            } else if (
              content.fieldGroupName ===
              "Work_Workfields_ContentRows_FullWidthBlock"
            ) {
              return <WorkFullWidth key={index} data={content} />
            } else if (
              content.fieldGroupName ===
              "Work_Workfields_ContentRows_VerticalHalfContent"
            ) {
              return <VerticalHalfContent key={index} data={content} />
            } else {
              return null
            }
          })}
        </div>
      </div>

      <WorkLayout disableList={true} />
    </Layout>
  )
}

export default WpWork
